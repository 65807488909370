<script setup>
import ReviewCard from '@components/ReviewCard.vue'
import Slider from '@components/Slider.vue'
import { chunk } from '@helpers/utils'

defineProps({
  reviews: {
    type: Array,
    required: true,
  },
})
</script>

<template>
  <Slider sm="1" md="1" :arrows="true" :dots="true" :scroll="false">
    <div v-for="(chunk, i) in chunk(reviews, 4)" :key="i" class="testimonials__reviews-page">
      <ReviewCard v-for="review in chunk" :key="review.id" :review="review" :showProduct="true" />
    </div>
  </Slider>
</template>

<style scoped lang="scss">

</style>
